import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { Table, Button, Spin } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { parseFullDate } from "../../config/formats";
import TEXTS from "../../config/texts";
import TableButtons from "../../components/TableButtons";
import { confirm } from "../../config/confirm";
import { getColumnSearchProps } from "../../config/config";
import ICONS from "../../config/icons";
import { REQUESTS } from "../../api/requests";
import SubresellerDrawer from "./components/SubresellerDrawer";
import ActivationDrawer from "./components/ActivationDrawer";

import "./index.scss";
import Pages from "../../components/Pages";
import InfoDrawer from "./components/InfoDrawer";
import userContext from "../../context/UserContext";
import { DataGrid } from "@mui/x-data-grid";
import Card from "../dashboard/components/Card";
import ServerSideToolbar from "../../components/ServerSideToolbar";

export default function Subresellers() {
  const { user, setUser } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [backupResellerdata, setbackupResellerdata] = useState([]);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({});
  const [resellersData, setResellersdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(null);
  const [actiavtionDrawer, setActivationDrawer] = useState(false);
  const [activationType, setActivationType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const searchInput = useRef(null);

  const translation = useSelector((state) => state.globalState.translation);

  // const columns = [
  //   {
  //     title: "#",
  //     dataIndex: "id",
  //     width: "60px",
  //     render: (TEXTS, record, index) => index + 1,
  //   },
  //   {
  //     title: `${translation["Name"] || TEXTS["Name"]}`,
  //     dataIndex: "name",
  //     key: "name",
  //     ...getColumnSearchProps(searchInput),
  //   },
  //   {
  //     title: `${translation["Email"] || TEXTS["Email"]}`,
  //     dataIndex: "email",
  //     key: "email",
  //     ...getColumnSearchProps(searchInput),
  //   },
  //   {
  //     title: `${translation["Credit"] || TEXTS["Credit"]}`,
  //     dataIndex: "total_activations",
  //     key: "total_activations",
  //     sorter: true,
  //   },
  //   {
  //     title: `${
  //       translation["Activated devices"] || TEXTS["Activated devices"]
  //     }`,
  //     dataIndex: "devices",
  //     key: "devices",
  //     render: (TEXTS, record, index) => {
  //       if (record && record.devices) {
  //         return record.devices.length;
  //       }
  //     },
  //   },
  //   {
  //     title: `${translation["IP address"] || TEXTS["IP address"]}`,
  //     dataIndex: "ip",
  //     key: "ip",
  //     ...getColumnSearchProps(searchInput),
  //   },
  //   {
  //     title: `${
  //       translation["Subresellers count"] || TEXTS["Subresellers count"]
  //     }`,
  //     dataIndex: "subresellers_count",
  //     key: "subresellers_count",
  //     sorter: true,
  //   },
  //   {
  //     title: `${translation["Date"] || TEXTS["Date"]}`,
  //     dataIndex: "createdAt",
  //     key: "createdAt",
  //     sorter: true,
  //     render: (text) => parseFullDate(text),
  //   },
  //   {
  //     title: "",
  //     key: "operation",
  //     align: "center",
  //     width: "2%",
  //     render: (text, record, index) => {
  //       return (
  //         <div onClick={(e) => e.stopPropagation()}>
  //           <TableButtons
  //             handleMenuClick={(e) => handleMenuClick(e, record)}
  //             buttons={[
  //               {
  //                 key: "edit",
  //                 text: translation["Edit"] || TEXTS["Edit"],
  //                 icon: ICONS.PEN,
  //               },
  //               {
  //                 key: "disable",
  //                 text: record.is_disabled
  //                   ? translation["Enable"] || TEXTS["Enable"]
  //                   : translation["Disable"] || TEXTS["Disable"],
  //                 icon: record.is_disabled ? ICONS.LOCK : ICONS.PADLOCK,
  //               },
  //               {
  //                 key: "Add Credit",
  //                 text: translation["Add Credit"] || TEXTS["Add Credit"],
  //                 icon: <PlusOutlined />,
  //               },
  //               {
  //                 key: "Take Credit",
  //                 text: translation["Take Credit"] || TEXTS["Take Credit"],
  //                 icon: <MinusOutlined />,
  //               },
  //             ]}
  //           />
  //         </div>
  //       );
  //     },
  //   },
  // ];

  const handleSearch = (value) => {
    setSearchValue(value);

    function searchTransactions(transactions, query) {
      const queryLower = String(query).toLowerCase();

      let filterarea = transactions.filter((transaction) => {
        return Object.values(transaction).some((value) => {
          let newvalue = value;
          if (String(value).includes("T")) {
            newvalue = parseFullDate(String(value));
          }

          return (
            value !== null &&
            String(newvalue).toLowerCase().includes(queryLower)
          );
        });
      });

      return filterarea;
    }

    setResellersdata(searchTransactions(backupResellerdata, value));
  };
  const columns = [
    // {
    //   flex: 0.25,
    //   field: "id",
    //   headerName: "Id",
    // },

    {
      flex: 0.25,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      align: "center",
      headerAlign: "center",
    },

    {
      flex: 0.25,
      minWidth: 180,
      field: "email",
      headerName: "Email",
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 0.25,
      minWidth: 180,
      field: "total_activations",
      headerName: "Total Activations",
      align: "center",
      headerAlign: "center",
    },

    {
      flex: 0.25,
      minWidth: 180,
      field: "devices",
      headerName: "Activated devices",
      align: "center",
      headerAlign: "center",
      valueGetter: (TEXTS, record, index) => {
        console.log(TEXTS);
        // document.querySelector(".MuiDataGrid-row");
        if (record && record.devices) {
          return record.devices.length;
        }
      },
    },
    {
      flex: 0.25,
      minWidth: 180,
      field: "ip",
      headerName: "IP address",
      align: "center",
      headerAlign: "center",
    },

    {
      flex: 0.25,
      minWidth: 180,
      field: "createdAt",
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      valueGetter: (text) => parseFullDate(text),
    },

    {
      flex: 0.25,
      minWidth: 60,
      field: "operation",
      headerName: "",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <TableButtons
              handleMenuClick={(e) => handleMenuClick(e, params.row)}
              buttons={[
                {
                  key: "edit",
                  text: translation["Edit"] || TEXTS["Edit"],
                  icon: ICONS.PEN,
                },
                {
                  key: "disable",
                  text: params.row.is_disabled
                    ? translation["Enable"] || TEXTS["Enable"]
                    : translation["Disable"] || TEXTS["Disable"],
                  icon: params.row.is_disabled ? ICONS.LOCK : ICONS.PADLOCK,
                },
                {
                  key: "Add Credit",
                  text: translation["Add Credit"] || TEXTS["Add Credit"],
                  icon: <PlusOutlined />,
                },
                {
                  key: "Take Credit",
                  text: translation["Take Credit"] || TEXTS["Take Credit"],
                  icon: <MinusOutlined />,
                },
              ]}
            />
          </div>
        );
      },
    },
  ];

  const disableSubreseller = (id) => {
    REQUESTS.SUBRESELLER_DISBALE(
      { id },
      (data) => {
        getSubresellers();
      },
      (err) => {}
    );
  };

  const handleMenuClick = (e, item) => {
    console.log(e);
    console.log(item);
    switch (e.key) {
      case "disable":
        confirm(
          item.is_disabled
            ? `${
                translation["Are you sure you want to enable?"] ||
                TEXTS["Are you sure you want to enable?"]
              }`
            : `${
                translation["Are you sure you want to disable?"] ||
                TEXTS["Are you sure you want to disable?"]
              }`,
          () => disableSubreseller(item.id),
          translation["OK"] || TEXTS["OK"],
          translation["Cancel"] || TEXTS["Cancel"]
        );
        break;

      case "edit":
        setUser({
          ...user,
          subresellerDrawer: true,
        });
        setVisible(true);
        setCurrent(item);
        break;
      case "Add Credit":
        setActivationDrawer(true);
        setCurrent(item);
        setActivationType("Add Credit");
        break;
      case "Take Credit":
        setActivationDrawer(true);
        setCurrent(item);
        setActivationType("Take Credit");
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getSubresellers = () => {
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
    };

    if (!query.search) {
      query.search = {};
    }

    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (search.ip) {
      query.search["ip"] = search.ip[0];
    }

    if (search.email) {
      query.search["email"] = search.email[0];
    }

    query.search = JSON.stringify(query.search);

    function callback(data) {
      setResellersdata(data.rows);
      setbackupResellerdata(data.rows);
      setTotal(data.count);
      setCurrentPage(data.currentPage);
      setLimit(data.limit);
    }

    REQUESTS.SUBRESELLERS.GET(query, callback);
  };

  useEffect(() => {
    console.log(resellersData);
    let timeout = setTimeout(() => {
      getSubresellers();
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, sort, currentPage, limit]);

  return (
    <Pages
      title={translation["My resellers"] || TEXTS["My resellers"]}
      // actions={
      //   <Button
      //     type="primary"
      //     icon={<PlusOutlined />}
      //     onClick={() => {
      //       setVisible(true);
      //       setCurrent(null);
      //     }}
      //     className="subreseller-page_add-btn"
      //   />
      // }
    >
      <Card>
        <div className="datagrid">
          <DataGrid
            autoHeight
            getRowClassName={(params) => {
              if (params["row"]["is_disabled"] == true) {
                return "row-disabled";
              }
            }}
            pagination
            rows={resellersData}
            rowCount={total}
            checkboxSelection
            columns={columns}
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[7, 10, 25, 50]}
            paginationModel={paginationModel}
            onRowClick={(record, rowIndex) => {
              setOpenInfoDrawer(true);
              setCurrent(record["row"]);
            }}
            onPaginationModelChange={setPaginationModel}
            slots={{ toolbar: ServerSideToolbar }}
            slotProps={{
              baseButton: {
                size: "medium",
                variant: "tonal",
              },
              toolbar: {
                value: searchValue,
                clearSearch: () => handleSearch(""),
                onChange: (event) => handleSearch(event.target.value),
              },
            }}
          />
        </div>
      </Card>

      {/* <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={resellersData}
        rowClassName={(record, index) =>
          resellersData[index].is_disabled ? "is_disabled" : "row"
        }
        loading={{
          spinning: loading,
          indicator: (
            <Spin
              indicator={
                <img
                  src="./assets/images/logo.svg"
                  style={{
                    width: 100,
                    height: 100,
                    transform: `translateY(-40px)`,
                  }}
                  alt=""
                />
              }
            />
          ),
        }}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setOpenInfoDrawer(true);
              setCurrent(record);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
      /> */}
      <SubresellerDrawer
        visible={user.subresellerDrawer}
        onClose={() => {
          setUser({
            ...user,
            subresellerDrawer: false,
          });
          setVisible(false);
          setCurrent(null);
        }}
        current={current}
        getSubresellers={getSubresellers}
      />
      <ActivationDrawer
        visible={actiavtionDrawer}
        onClose={() => {
          setActivationDrawer(false);
          setCurrent(null);
        }}
        activationType={activationType}
        getSubresellers={getSubresellers}
        current={current}
      />
      <InfoDrawer
        visible={openInfoDrawer}
        current={current}
        onClose={() => {
          setOpenInfoDrawer(false);
          setCurrent(null);
        }}
      />
    </Pages>
  );
}
