import React, { useState } from "react";
import UserContext from "./UserContext";
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    BuyMoreActivationsDrawer: false,
    visibleBuyMoreActivationsDrawer: false,
    VisibleActivateDeviceDrawer: false,
    VisibleAddExistingDeviceDrawer: false,
    visibleBuyMoreActivationsDrawer: false,
    subresellerDrawer: false,
    darkmode: false,
  });
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
