import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import Card from "./Card";

import { REQUESTS } from "../../../api/requests";
import style from "../style/resellerStatistics.module.scss";
import TEXTS from "../../../config/texts";
import Chart from "react-apexcharts";

export default function ResellerStatistics() {
  const translation = useSelector((state) => state.globalState.translation);

  const [currentMonth, setCurrentMonth] = useState("");
  const [activated, setActivated] = useState({
    total: "",
    monthly: "",
    today: "",
  });
  const [credit, setCredit] = useState({ total: "", monthly: "", today: "" });

  const [subreseller, setSubreseller] = useState({
    total: "",
    monthly: "",
    today: "",
  });

  const month = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const getActivationdata = () => {
    function callback(data) {
      if (data.reseller) {
        if (data.reseller.activated) {
          setActivated({
            total: data.reseller.activated.activation_count,
            monthly: data.reseller.activated.activations_this_month,
            today: data.reseller.activated.activations_today,
          });
        }

        if (data.reseller.activation) {
          setCredit({
            total: data.reseller.activation.spend_total,
            today: data.reseller.activation.spend_today,
            monthly: data.reseller.activation.spend_month,
          });
        }
      }
    }

    REQUESTS.DASHBOARD.CARDS(callback);
  };

  const getSubresellers = () => {
    function callback(data) {
      setSubreseller({
        total: data.count,
        monthly: data.mounth,
        today: data.day,
      });
    }

    REQUESTS.SUBRESELLERS_STATISTICS(callback);
  };

  useEffect(() => {
    const date = new Date();
    setCurrentMonth(month[date.getMonth()]);

    getSubresellers();
    getActivationdata();
  }, []);

  let state = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        theme: "light", // Optional: you can choose light or dark themes
        cssClass: "custom-tooltip", // Custom CSS class for tooltip
      },
      plotOptions: {
        bar: { horizontal: false, borderRadius: 5, columnWidth: "45%" },
      },
      colors: ["#887EF2"],
      grid: {
        show: false,
      },

      xaxis: {
        categories: ["Total", "May", "Today"],
        show: false,
        labels: {
          style: {
            colors: "#333s",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    },
  };

  return (
    <div>
      <div className={style["reseller-statistics-cards"]}>
        <Card>
          <h1 className={style["reseller-statistics-cards__content__title"]}>
            {translation["Activations"] || TEXTS["Activations"]}
          </h1>
          <p className={style["reseller-statistics-cards__content__para"]}>
            Activations Analysis Overview
          </p>

          <div
            className={style["reseller-statistics-cards__content__gridChart"]}
          >
            <div>
              <h1
                className={
                  style["reseller-statistics-cards__content__todayprice"]
                }
              >
                {activated.today}
              </h1>
              <p
                className={
                  style["reseller-statistics-cards__content__todaytext"]
                }
              >
                You informed of today compared to yesterday
              </p>
            </div>

            <div>
              <Chart
                options={state.options}
                series={[
                  {
                    name: "Activations",
                    data: [activated.total, activated.monthly, activated.today],
                  },
                ]}
                type="bar"
              />
            </div>
          </div>

          <div
            className={
              style["reseller-statistics-cards__content__statisticsarea"]
            }
          >
            <div>
              <h1>
                <span style={{ background: `#7367F0` }}></span>Total
              </h1>
              <p>{activated.total}</p>
            </div>

            <div>
              <h1>
                <span style={{ background: `#09CEE7` }}></span>May
              </h1>
              <p>{activated.monthly}</p>
            </div>
            <div>
              <h1>
                <span style={{ background: `#EA5355` }}></span>Today
              </h1>
              <p>{activated.today}</p>
            </div>
          </div>
        </Card>

        <Card>
          <h1 className={style["reseller-statistics-cards__content__title"]}>
            {translation["Credit"] || TEXTS["Credit"]}
          </h1>
          <p className={style["reseller-statistics-cards__content__para"]}>
            Credit Analysis Overview
          </p>

          <div
            className={style["reseller-statistics-cards__content__gridChart"]}
          >
            <div>
              <h1
                className={
                  style["reseller-statistics-cards__content__todayprice"]
                }
              >
                {credit.today}
              </h1>
              <p
                className={
                  style["reseller-statistics-cards__content__todaytext"]
                }
              >
                You informed of today compared to yesterday
              </p>
            </div>

            <div>
              <Chart
                options={state.options}
                series={[
                  {
                    name: "Credit",
                    data: [credit.total, credit.monthly, credit.today],
                  },
                ]}
                type="bar"
              />
            </div>
          </div>

          <div
            className={
              style["reseller-statistics-cards__content__statisticsarea"]
            }
          >
            <div>
              <h1>
                <span style={{ background: `#7367F0` }}></span>Total
              </h1>
              <p>{credit.total}</p>
            </div>
            <div>
              <h1>
                <span style={{ background: `#09CEE7` }}></span>May
              </h1>
              <p>{credit.monthly}</p>
            </div>
            <div>
              <h1>
                <span style={{ background: `#EA5355` }}></span>Today
              </h1>
              <p>{credit.today}</p>
            </div>
          </div>
        </Card>

        <Card>
          <h1 className={style["reseller-statistics-cards__content__title"]}>
            {translation["Subreseller"] || TEXTS["Subreseller"]}
          </h1>
          <p className={style["reseller-statistics-cards__content__para"]}>
            Subreseller Analysis Overview
          </p>

          <div
            className={style["reseller-statistics-cards__content__gridChart"]}
          >
            <div>
              <h1
                className={
                  style["reseller-statistics-cards__content__todayprice"]
                }
              >
                {subreseller.today}
              </h1>
              <p
                className={
                  style["reseller-statistics-cards__content__todaytext"]
                }
              >
                You informed of today compared to yesterday
              </p>
            </div>

            <div>
              <Chart
                options={state.options}
                series={[
                  {
                    name: "Subreseller",
                    data: [
                      subreseller.total,
                      subreseller.monthly,
                      subreseller.today,
                    ],
                  },
                ]}
                type="bar"
              />
            </div>
          </div>

          <div
            className={
              style["reseller-statistics-cards__content__statisticsarea"]
            }
          >
            <div>
              <h1>
                <span style={{ background: `#7367F0` }}></span>Total
              </h1>
              <p>{subreseller.total}</p>
            </div>
            <div>
              <h1>
                <span style={{ background: `#09CEE7` }}></span>May
              </h1>
              <p>{subreseller.monthly}</p>
            </div>
            <div>
              <h1>
                <span style={{ background: `#EA5355` }}></span>Today
              </h1>
              <p>{subreseller.today}</p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
