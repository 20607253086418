const TEXTS = {
  // a
  Activations: "Activations",
  Activation: "Activation",
  Activated: "Activated",
  "Activations history": "Activations history",
  "Activation history": "Activation history",
  "Activation buy history": "Activation buy history",
  "Activate device": "Activate device",
  "Add existing device": "Add existing device",
  "Activation count": "Activation count",
  "Activations count month": "Activations count month",
  "Activations month": "Activations month",
  "Activations today": "Activations today",
  "Activation expired": "Activation expired",
  "Add new link": "Add new link",
  "Activation given": "Activation given",
  "Activation taken": "Activation taken",
  "Add Reseller": "Add Reseller",
  "Add activations": "Add activations",
  Amount: "Amount",
  Accepted: "Accepted",
  Address: "Address",
  "Activations spent": "Activations spent",
  "Activated until": "Activated until",
  "Add playlist": "Add playlist",
  "Activated devices": "Activated devices",
  "Are you sure you want to enable?": "Are you sure you want to enable?",
  "Are you sure you want to disable?": "Are you sure you want to disable?",
  "Add Credit": "Add Credit",
  "Activated today": "Activated today",
  "Activated devices": "Activated devices",
  Activate: "Activate",
  // b
  "Buy activations": "Buy activations",
  "Buy more activations": "Buy more activations",
  "Back to login": "Back to login",
  "Balance on hold": "Balance on hold",
  "Buy Credits": "Buy Credits",
  "Buy more Credits": "Buy more Credits",
  // c
  Cancel: "Cancel",
  "Choose payment method": "Choose payment method",
  "Confirm your paypal cashout email": "Confirm your paypal cashout email",
  "Confirm paypal cashout email": "Confirm paypal cashout email",
  "Custom transfer": "Custom transfer",
  "Confirm password": "Confirm password",
  Comment: "Comment",
  "Code from TV": "Code from TV",
  Count: "Count",
  Clicks: "Clicks",
  Company: "Company",
  Country: "Country",
  Credit: "Credit",
  "Credits history": "Credits history",
  Credits: "Credits",
  Code: "Code",
  "Confirm PIN": "Confirm PIN",
  // d
  Date: "Created date",
  Devices: "Devices",
  Device: "Device",
  "Device model": "Device model",
  Dashboard: "Dashboard",
  Description: "Description",
  "Do you want to delete link ?": "Do you want to delete link ?",
  "Do you want to logout ?": "Do you want to logout ?",
  "Do you want to delete?": "Do you want to delete?",
  "Device key": "Device key",
  Delete: "Delete",
  Disable: "Disable",
  DNS: "DNS",
  // e
  "Earnings this month": "Earnings this month",
  "Edit profile": "Edit profile",
  "Edit link": "Edit link",
  "Enter link name": "Enter link name",
  "Earnings wallet": "Earnings wallet",
  "Enter price": "Enter price",
  "Enter your paypal cashout email": "Enter your paypal cashout email",
  "Enter your name": "Enter your name",
  "Enter your surname": "Enter your surname",
  "Enter your email": "Enter your email",
  "Edit playlist": "Edit playlist",
  "Enter your swift/bic code": "Enter your swift/bic code",
  "Enter your Iban account number": "Enter your Iban account number",
  "Enter your phone number": "Enter your phone number",
  "Enter your registration number": "Enter your registration number",
  "Enter your address": "Enter your address",
  "Enter your company name": "Enter your company name",
  "Enter your Xtream host": "Enter your Xtream host",
  "Enter device mac address": "Enter device mac address",
  "Enter code": "Enter code",
  "Edit Reseller": "Edit Reseller",
  "Enter text": "Enter text",
  Email: "Email",
  Edit: "Edit",
  Enable: "Enable",
  Earnings: "Earnings",
  "Existing device": "Existing device",
  // f
  "Forgot password": "Forgot password",
  "For support click button below": "For support click button below",
  // g
  "Get code": "Get code",
  // h
  Host: "Host",
  // i
  "IP address": "IP address",
  "Iban account number": "Iban account number",
  // j
  // k
  // l
  Link: "Link",
  Links: "Links",
  Login: "Login",
  Logout: "Logout",
  "Links today": "Links today",
  "Link description": "Link description",
  // m
  "Mac address": "Mac address",
  Method: "Method",
  "My resellers": "My resellers",
  "Mac address is valid": "Mac address is valid",
  "Mac address is not valid": "Mac address is not valid",
  // n
  No: "No",
  Name: "Name",
  "New password": "New password",
  // o
  OK: "OK",
  Or: "Or",
  // p
  "Privacy policy": "Privacy policy",
  Package: "Package",
  Playlist: "Playlist",
  "Playlist name": "Playlist name",
  "Playlist url": "Playlist url",
  Price: "Price",
  Password: "Password",
  "Please check with your payment provider about the status of the account or contact our support":
    "Please check with your payment provider about the status of the account or contact our support",
  "Please confirm your password!": "Please confirm your password!",
  Phone: "Phone",
  "Phone number": "Phone number",
  "PayPal cashout email": "PayPal cashout email",
  "Protect Playlist": "Protect Playlist",
  // q
  // r
  Referral: "Referral",
  Reseller: "Reseller",
  "Referral links": "Referral links",
  "Reset password": "Reset password",
  Reset: "Reset",
  "Registration number": "Registration number",
  "Reset email": "Reset email",
  "Reseller name": "Reseller name",
  "Reseller email": "Reseller email",
  "Reseller info": "Reseller info",
  // s
  Save: "Save",
  Status: "Status",
  "Select package": "Select package",
  Subreseller: "Subreseller",
  "Send email": "Send email",
  "Subresellers count": "Subresellers count",
  Surname: "Surname",
  "Select your country": "Select your country",
  "Swift/Bic code": "Swift/Bic code",
  "Selected currency is not supported by Paypal. Contact to administration":
    "Selected currency is not supported by Paypal. Contact to administration",
  Subresellers: "Subresellers",
  "Set PIN": "Set PIN",
  // t
  "This field is required!": "This field is required!",
  "Transaction id": "Transaction id",
  "Total links": "Total links",
  "Total activated": "Total activated",
  "Total earnings": "Total earnings",
  Today: "Today",
  Total: "Total",
  "The input is not valid Email!": "The input is not valid Email!",
  "The two passwords that you entered do not match!":
    "The two passwords that you entered do not match!",
  "Take Credit": "Take Credit",
  "Take activations": "Take activations",
  "The two email that you entered do not match!":
    "The two email that you entered do not match!",
  "Total Activations": "Total Activations",
  // u
  Username: "Username",
  // v
  // w
  "Withdrawals history": "Withdrawals history",
  "Withdraw funds": "Withdraw funds",
  "Withdraw history": "Withdraw history",
  "Wired transfer": "Wired transfer",
  // x
  "Xtream host": "Xtream host",
  // y
  Yes: "Yes",
  "Your payment was done successfully": "Your payment was done successfully",
  // z
};

export default TEXTS;
