// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { GridToolbarExport } from "@mui/x-data-grid";

// ** Custom Component Import
import CustomTextField from "./mui/text-field/index";

// ** Icon Imports
import Icon from "../components/icon";

const ServerSideToolbar = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "0px",
        p: (theme) => theme.spacing(2, 2, 2, 2),
      }}
    >
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      <CustomTextField
        value={props.value}
        placeholder="Search…"
        onChange={props.onChange}
        InputProps={{
          startAdornment: (
            <Box sx={{ mr: 2, display: "flex" }}>
              <Icon fontSize="1.25rem" icon="tabler:search" />
            </Box>
          ),
          endAdornment: (
            <IconButton
              size="small"
              title="Clear"
              aria-label="Clear"
              onClick={props.clearSearch}
            >
              <Icon fontSize="1.25rem" icon="tabler:x" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          "& .MuiInputBase-root > svg": {
            mr: 2,
          },
        }}
      />
    </Box>
  );
};

export default ServerSideToolbar;
