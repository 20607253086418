import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Popover, Table } from "antd";

import { REQUESTS } from "../../api/requests";

import TEXTS from "../../config/texts";
import ICONS from "../../config/icons";
import { confirm } from "../../config/confirm";
import { parseFullDate } from "../../config/formats";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
} from "../../config/config";

import Pages from "../../components/Pages";
import Tags from "../../components/Tags";
import TableButtons from "../../components/TableButtons";
import ReferralLinksDrawer from "./components/ReferralLinksDrawer";

import classes from "./index.module.scss";

// ReferralLinks page is used to display all the referral links created by the user.
// The user can also create a new referral link from this page. Also, the user can edit or delete the referral link.
// The user can also see the number of clicks on the referral link and go to the website where the referral
// link was clicked. The user can also see the number of registrations made by the referral link.

export default function ReferralLinks() {
  const translation = useSelector((state) => state.globalState.translation);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [date, setDate] = useState("");
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(searchParams.get("limit") || 10);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [referralData, setReferralData] = useState([]);
  const [referralItem, setReferralItem] = useState(null);
  const [visibleAddNewLinkDrawer, setVisibleAddNewLinkDrawer] = useState(false);

  const [search, setSearch] = useState(
    searchParams.get("search") || {
      name: null,
      description: null,
    }
  );

  const searchInput = useRef(null);

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        confirm(
          translation["Do you want to delete?"] ||
            TEXTS["Do you want to delete?"],
          () => deleteLink(item.id),
          translation["Yes"] || TEXTS["Yes"],
          translation["No"] || TEXTS["No"]
        );

        break;
      case "edit":
        setReferralItem(item);
        setVisibleAddNewLinkDrawer(true);
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const removeSearchSorter = () => {
    setSearch({
      name: null,
      description: null,
    });
    setSort(["id", "DESC"]);
  };

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      key: "index",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: `${translation["Name"] || TEXTS["Name"]}`,
      dataIndex: "name",
      align: "center",
      key: "name",
      ...getColumnSearchProps(searchInput),
    },
    {
      title: `${translation["Description"] || TEXTS["Description"]}`,
      dataIndex: `description`,
      align: "center",
      width: "150px",
      key: "description",
      render: (record, text, index) => {
        if (record) {
          const content = <div>{record}</div>;
          return (
            <Popover content={content}>
              <div className={classes["ref-link"]}>
                <span>{record}</span>
              </div>
            </Popover>
          );
        } else {
          return <Tags />;
        }
      },
      ...getColumnSearchProps(searchInput),
    },

    {
      title: `${translation["Clicks"] || TEXTS["Clicks"]}`,
      dataIndex: "clicks",
      align: "center",
      key: "clicks",
      sorter: true,
    },

    {
      title: `${translation["Link"] || TEXTS["Link"]}`,
      dataIndex: "link",
      align: "center",
      key: "link",
      width: "150px",
      render: (record) => {
        const content = <div>{record}</div>;
        return (
          <Popover content={content}>
            <div className={classes["ref-link"]}>
              <a style={{ color: "#1890FF" }} href={record} target={"_blank"}>
                {record}
              </a>
            </div>
          </Popover>
        );
      },
    },

    {
      title: `${translation["Date"] || TEXTS["Date"]}`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      render: (record, text, index) => {
        return parseFullDate(record);
      },
      ...getColumnDateProps((e) => setDate(e)),
    },

    {
      title: "",
      key: "operation",
      align: "center",
      width: "2%",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: translation["Edit"] || TEXTS["Edit"],
              icon: ICONS.PEN,
            },
            {
              key: "delete",
              text: record.is_disabled
                ? translation["Enable"] || TEXTS["Enable"]
                : translation["Delete"] || TEXTS["Delete"],
              icon: ICONS.TRASH,
            },
          ]}
        />
      ),
    },
  ];

  const getReferralLink = () => {
    setLoading(true);
    const query = {
      limit,
      search: {},
      pagination: 1,
      page: currentPage,
      sort: JSON.stringify(sort),
    };

    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (search.description) {
      query.search["description"] = search.description[0];
    }

    if (search.link) {
      query.search["link"] = search.link[0];
    }

    query.search = JSON.stringify(query.search);

    const queryDate = getQueryDate(date);

    if (queryDate) query.between = queryDate;

    function callback(data) {
      setLoading(false);
      setTotal(data.count);
      setLimit(data.limit);
      setReferralData(data.rows);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    navigate({
      search: `${createSearchParams(query)}`,
    });

    REQUESTS.REFERRAL_LINKS.GET(query, callback, errorCallback);
  };

  const deleteLink = (id) => {
    function callback() {
      getReferralLink();

      if (search.name !== null || search.description !== null) {
        removeSearchSorter();
      }
    }

    REQUESTS.REFERRAL_LINKS.DELETE(id, callback);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getReferralLink();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search, sort, date, limit, currentPage]);

  return (
    <Pages
      title={translation["Referral links"] || TEXTS["Referral links"]}
      onClick={() => setVisibleAddNewLinkDrawer(true)}
      actions={translation["Add new link"] || TEXTS["Add new link"]}
    >
      <Table
        loading={loading}
        bordered
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        columns={columns}
        dataSource={referralData}
        scroll={{
          x: "max-content",
        }}
        size="small"
      />

      <ReferralLinksDrawer
        visible={visibleAddNewLinkDrawer}
        onClose={() => {
          setReferralItem(null);
          setVisibleAddNewLinkDrawer(false);
        }}
        current={referralItem}
        getReferralLink={getReferralLink}
        removeSearchSorter={removeSearchSorter}
      />
    </Pages>
  );
}
