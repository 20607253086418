import { Tag } from "antd";

const Tags = () => {
  return (
    <Tag color="red" style={{ borderRadius: 10 }}>
      N/A
    </Tag>
  );
};

export default Tags;
