import React, { useEffect, useState } from "react";
import Message from "../../components/message";
import { useSelector } from "react-redux";
import { REQUESTS } from "../../api/requests";
import TEXTS from "../../config/texts";

import classes from "../styles/typeCodeDrawer.module.scss";

import { Button, Drawer, Form, Input, Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import CustomTextField from "../../components/mui/text-field";

const TypeCodeDrawer = ({
  visible,
  onClose,
  closeResetEmailTypeCodeDrawer,
  getProfile,
  okMessage,
  setOkMessage,
  email,
}) => {
  const translation = useSelector((state) => state.globalState.translation);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      email: email,
      code: values.code,
    };

    function callback(data) {
      setLoading(false);
      // onClose();
      closeResetEmailTypeCodeDrawer();
      getProfile();

      setMessage({
        text: data,
        type: true,
      });
    }

    function errorCallback(error) {
      setLoading(false);

      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.RESET_EMAIL_PUT(body, callback, errorCallback);
  };

  const onFinishFailed = (error) => {
    setLoading(false);
  };

  const onValuesChange = () => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  // const cancel = () => {
  //     onClose();
  //     setMessage({
  //         text: "",
  //         type: true,
  //     });
  //
  //     form.setFields([{ name: "code", value: "" }]);
  // };

  useEffect(() => {
    if (!visible) {
      setMessage({
        text: "",
        type: true,
      });

      setOkMessage();
    } else {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      width={400}
      title={translation["Code"] || TEXTS["Code"]}
      placement="right"
      onClose={onClose}
      open={visible}
      onCancel={onClose}
      footer={[]}
    >
      <Form
        form={form}
        name="profile"
        layout={"vertical"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Message message={okMessage} />
        <div>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message:
                  translation["This field is required!"] ||
                  TEXTS["This field is required!"],
              },
            ]}
          >
            <div className={classes["input-flex-area"]}>
              <label htmlFor="#">{translation["Code"] || TEXTS["Code"]}</label>

              <div style={{ width: "100%" }}>
                <CustomTextField
                  onKeyDown={onInputEnter}
                  placeholder={translation["Code"] || TEXTS["Code"]}
                />
              </div>
            </div>

            {/* <Input onKeyDown={onInputEnter} /> */}
          </Form.Item>

          <Message message={message} />

          <Form.Item className={classes["button"]}>
            <Button
              onClick={onSubmit}
              loading={loading}
              icon={<CheckOutlined />}
              type="primary"
              size={"large"}
            >
              {translation["Save"] || TEXTS["Save"]}
            </Button>
          </Form.Item>

          {/*<div className={classes["button"]}>*/}
          {/*    */}
          {/*    <div className={classes["for-item-buttons"]}>*/}
          {/*        /!*<Form.Item>*!/*/}
          {/*        /!*    <Button*!/*/}
          {/*        /!*        onClick={onSubmit}*!/*/}
          {/*        /!*        loading={loading}*!/*/}
          {/*        /!*        icon={<CheckOutlined />}*!/*/}
          {/*        /!*        type="primary"*!/*/}
          {/*        /!*        size={"large"}*!/*/}
          {/*        /!*    >*!/*/}
          {/*        /!*        {translation["Save"] || TEXTS["Save"]}*!/*/}
          {/*        /!*    </Button>*!/*/}
          {/*        /!*</Form.Item>*!/*/}

          {/*        /!*<Form.Item>*!/*/}
          {/*        /!*    <Button*!/*/}
          {/*        /!*        type="primary"*!/*/}
          {/*        /!*        size={"large"}*!/*/}
          {/*        /!*        onClick={cancel}*!/*/}
          {/*        /!*    >*!/*/}
          {/*        /!*        {translation["Cancel"] || TEXTS["Cancel"]}*!/*/}
          {/*        /!*    </Button>*!/*/}
          {/*        /!*</Form.Item>*!/*/}
          {/*    </div>*/}
          {/*</div>*/}
        </div>
      </Form>
    </Modal>
  );
};

export default TypeCodeDrawer;
