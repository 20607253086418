import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Form, Input, Modal } from "antd";
import { REQUESTS } from "../../../api/requests";
import TEXTS from "../../../config/texts";
import GetCodeDrawer from "./GetCodeDrawer";

import Message from "../../../components/message";
import ValidateMacAddress from "../../../components/ValidateMacAddress";

import classes from "../style/addExistingDeviceDrawer.module.scss";

const AddExistingDeviceDrawer = ({
  visible,
  onClose,
  getDevices,
  removeSearchSorter,
}) => {
  const translation = useSelector((state) => state.globalState.translation);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [visibleGetCodeDrawer, setVisibleGetCodeDrawer] = useState(false);
  const [macValue, setMacValue] = useState("");

  const [device, setDevice] = useState(null);

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    if (device) {
      if (device.auth_type === "otp") {
        REQUESTS.ACTIVATED_DEVICE.GET_CODE(
          { ...values, mac: macValue },
          () => {
            setLoading(false);
            setVisibleGetCodeDrawer(true);
          },
          (error) => {
            setLoading(false);
            setMessage({
              text: error,
              type: false,
            });
          }
        );
      } else if (device.auth_type === "device_key") {
        REQUESTS.ACTIVATED_DEVICE.ADD_EXISTING_DEVICE(
          { mac: macValue, key: values.key },
          (data) => {
            setLoading(false);
            onClose();
            setMacValue("");
          },
          (err) => {
            setLoading(false);
            setMessage({
              text: err,
              type: false,
            });
          }
        );
      }
    }
  };

  const onValuesChange = (change, all) => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  useEffect(() => {
    if (!visible) {
      setLoading(false);
      removeMessage();
    } else {
      form.resetFields();
    }
  }, [visible]);

  const closeGetCodeDrawer = () => {
    onClose();
    setVisibleGetCodeDrawer(false);
    getDevices();
  };

  useEffect(() => {
    if (macValue) {
      setDevice(null);
    }
  }, [macValue]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <>
      <Modal
        width={windowWidth > 600 ? 600 : "75vw"}
        title={translation["Existing device"] || TEXTS["Existing device"]}
        centered
        onClose={() => {
          onClose();
          setMacValue("");
          setDevice(null);
        }}
        onOk={() => {
          onClose();
          setMacValue("");
          setDevice(null);
        }}
        onCancel={() => {
          onClose();
          setMacValue("");
          setDevice(null);
        }}
        open={visible}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          name="add-existing-device"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            remember: true,
          }}
        >
          <div></div>
          <ValidateMacAddress
            value={macValue}
            setValue={setMacValue}
            setDevice={setDevice}
          />
          {device &&
            (device.auth_type === "otp" ? (
              <>
                <Message message={message} />

                <Form.Item className={classes["form-get-code-button"]}>
                  <Button type="primary" loading={loading} onClick={onSubmit}>
                    {translation["Get code"] || TEXTS["Get code"]}
                  </Button>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name="key"
                  label={translation["Device key"] || TEXTS["Device key"]}
                >
                  <Input
                    placeholder={
                      translation["Enter code"] || TEXTS["Enter code"]
                    }
                  />
                </Form.Item>
                <Message message={message} />

                <Form.Item className={classes["form-get-code-button"]}>
                  <Button type="primary" loading={loading} onClick={onSubmit}>
                    {translation["Save"] || TEXTS["Save"]}
                  </Button>
                </Form.Item>
              </>
            ))}
        </Form>
      </Modal>

      <GetCodeDrawer
        visible={visibleGetCodeDrawer}
        onClose={() => setVisibleGetCodeDrawer(false)}
        mac={macValue}
        closeGetCodeDrawer={closeGetCodeDrawer}
        removeSearch={removeSearchSorter}
      />
    </>
  );
};

export default AddExistingDeviceDrawer;
