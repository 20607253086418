// ** MUI Import
import MenuItem from "@mui/material/MenuItem";

// ** Custom Component Import
import CustomTextField from "../text-field/index";

const SelectCustom = ({ children, placeholder }) => {
  return (
    <div>
      <CustomTextField select>
        <MenuItem value={placeholder} selected>
          {placeholder}
        </MenuItem>
        {children}
      </CustomTextField>
    </div>
  );
};

export default SelectCustom;
