import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import TEXTS from "../../config/texts";

import Pages from "../../components/Pages";
import ResellerStatistics from "./components/ResellerStatistics";
import ReferralStatistics from "./components/ReferralStatistics";
import BuyMoreActivationsDrawer from "../../layout/components/BuyMoreActivationsDrawer";
import userContext from "../../context/UserContext";

// Dashboard page for showing statistics of reseller and referral. We are using useSelector to get the profile and translation from the global state.
// Profile is used to check if the user is reseller or referral. When the user is reseller he can see the reseller statistics and when the user is referral
// he can see the referral statistics. Translation is used to get the translation of the page title.

export default function DashboardPage() {
  const { user, setUser } = useContext(userContext);
  const profile = useSelector((state) => state.globalState.profile);
  const translation = useSelector((state) => state.globalState.translation);

  return (
    <Pages
      title={translation["Dashboard"] || TEXTS["Dashboard"]}
      onClick={() =>
        setUser({
          ...user,
          BuyMoreActivationsDrawer: true,
        })
      }
      // actions={translation["Buy more Credits"] || TEXTS["Buy more Credits"]}
    >
      {profile && profile.reseller && profile.reseller.is_reseller && (
        <ResellerStatistics />
      )}

      {profile.reseller?.is_referral && <ReferralStatistics />}
      <BuyMoreActivationsDrawer
        visible={user.BuyMoreActivationsDrawer}
        onClose={() =>
          setUser({
            ...user,
            BuyMoreActivationsDrawer: false,
          })
        }
      />
    </Pages>
  );
}
