import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Table, Button, Spin, Typography } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { REQUESTS } from "../../api/requests";

import ICONS from "../../config/icons";
import { parseFullDate } from "../../config/formats";

import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
} from "../../config/config";

import Pages from "../../components/Pages";
import DeviceDrawer from "./components/DeviceDrawer";
import TableButtons from "../../components/TableButtons";
import ActivateDeviceDrawer from "./components/ActivateDeviceDrawer";
import AddExistingDeviceDrawer from "./components/AddExistingDeviceDrawer";

import PlaylistsDrawer from "./components/PlaylistsDrawer";
import { confirm } from "../../config/confirm";

import macMaskInput from "../../components/macMaskInput";
import TEXTS from "../../config/texts";

import classes from "./index.module.scss";
import userContext from "../../context/UserContext";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import Card from "../dashboard/components/Card";
import ServerSideToolbar from "../../components/ServerSideToolbar";

// Devices page for showing the list of devices. We are using useSelector to get the translation from the global state. Translation is used to get the t
// ranslation of the page title.

export default function Devices() {
  const [rows, setRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("full_name");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  function loadServerRows(currentPage, data) {
    return data.slice(
      currentPage * paginationModel.pageSize,
      (currentPage + 1) * paginationModel.pageSize
    );
  }

  const { user, setUser } = useContext(userContext);

  const translation = useSelector((state) => state.globalState.translation);

  const profile = useSelector((state) => state.globalState.profile);

  // Search params are used to get the search params from the url. We are using them to set the initial values of the state.
  const [searchParams] = useSearchParams();

  // Devices state is used to store the list of devices. We are using it to show the list of devices in the table.
  const [devices, setDevices] = useState([]);

  // Visible device drawer state is used to show or hide the device drawer. We are using it to show the device drawer when the user clicks on the device
  const [visibleDeviceDrawer, setVisibleDeviceDrawer] = useState(false);

  // Visible activate device drawer state is used to show or hide the activate device drawer. We are using it to show the activate device drawer when the
  // user clicks on the activate device button.
  const [visibleActivateDeviceDrawer, setVisibleActivateDeviceDrawer] =
    useState(false);

  // Visible add existing device drawer state is used to show or hide the add existing device drawer. We are using it to show the add existing device
  // drawe when the user clicks on the add existing device button.
  const [visibleAddExistingDeviceDrawer, setVisibleAddExistingDeviceDrawer] =
    useState(false);

  // Visible playlist drawer state is used to show or hide the playlist drawer. We are using it to show the playlist drawer when the user clicks on the
  // playlist button.
  const [visiblePlaylistDrawer, setVisiblePlaylistDrawer] = useState(false);

  // Date state is used to store the date. We are using it to show the date in the date picker.
  const [date, setDate] = useState("");

  // Activation expired state is used to store the activation expired. We are using it to show the activation expired in the date picker.
  const [activationExpired, setActivationExpired] = useState("");

  // Total state is used to store the total number of devices. We are using it to show the total number of devices in the pagination
  const [total, setTotal] = useState(0);

  // Limit state is used to store the limit of the devices. We are using it to show the limit of the devices in the pagination. We are also using it to
  // set the limit of the devices in the table.
  const [limit, setLimit] = useState(searchParams.get("limit") || 10);

  // Loading state is used to show the loading animation. We are using it to show the loading animation when the user is fetching the devices.
  const [loading, setLoading] = useState(false);

  // Sort state is used to store the sort of the devices. We are using it to show the sort of the devices in the table.
  const [sort, setSort] = useState(["id", "DESC"]);

  // Current page state is used to store the current page of the devices. We are using it to show the current page of the devices in the pagination.
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);

  // Device id state is used to store the device id. We are using it to show the device id in the device drawer.
  const [deviceId, setDeviceId] = useState(null);

  // Search state is used to store the search of the devices. We are using it to show the search of the devices in the table.
  const [search, setSearch] = useState(
    searchParams.get("search") || {
      mac: null,
    }
  );

  // Search input is used to store the search input. We are using it to get the search input value.
  const searchInput = useRef(null);

  const [currentDevice, setCurrentDevice] = useState(null);
  const [backupDevicedata, setbackupDevicedata] = useState([]);

  const deleteDevice = (id) => {
    function callback() {
      getDevices();
    }
    function errorCallback() {}
    REQUESTS.ACTIVATED_DEVICE.DELETE({ id }, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "Playlist":
        setDeviceId(item.id);
        setVisiblePlaylistDrawer(true);
        break;
      case "Delete":
        confirm(
          translation["Do you want to delete?"] ||
            TEXTS["Do you want to delete?"],
          () => deleteDevice(item.id),
          translation["Yes"] || TEXTS["Yes"],
          translation["No"] || TEXTS["No"]
        );
        break;
      case "Activate":
        setVisibleActivateDeviceDrawer(true);
        setCurrentDevice(item);
        break;
      default:
        break;
    }
  };

  // Handle table change is used to handle the table change. We are using it to set the sort, search, current page and limit of the devices.
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  // removeSearchSorter is used to remove the search and sorter. We are using it to remove the search and sorter when the user clicks on the clear
  // button.
  const removeSearchSorter = () => {
    setSearch({
      mac: null,
    });
    setSort(["id", "DESC"]);
  };

  const columns = [
    // {
    //   flex: 0.25,
    //   field: "id",
    //   headerName: "Id",
    // },
    {
      flex: 0.25,
      minWidth: 180,
      field: "mac",
      headerName: "Mac Address",
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 0.25,
      minWidth: 180,
      field: "comment",
      headerName: "Comment",
      align: "center",
      headerAlign: "center",
      valueGetter: (value, row) => `${row?.reseller_activation?.comment}`,
    },
    {
      flex: 0.25,
      minWidth: 150,
      field: "model",
      align: "center",
      headerAlign: "center",
      headerName: "Device model",
    },

    {
      flex: 0.25,
      minWidth: 100,
      field: "package_name",
      headerName: "Package",
      align: "center",
      headerAlign: "center",
      valueGetter: (text, record, index) => {
        return record?.reseller_activation?.package_name;
      },
    },
    {
      flex: 0.25,
      minWidth: 80,
      field: "price",
      headerName: "Price",
      align: "center",
      headerAlign: "center",
      valueGetter: (text, record) => {
        return (
          record &&
          record.reseller_activation &&
          record.reseller_activation.price &&
          record.reseller_activation.price +
            " " +
            record.reseller_activation.currency?.toUpperCase()
        );
      },
    },
    {
      flex: 0.25,
      minWidth: 150,
      field: "activations_spent",
      headerName: "Activations spent",
      align: "center",
      headerAlign: "center",
      valueGetter: (text, record, index) => {
        return record?.reseller_activation?.activations_spent;
      },
    },
    {
      flex: 0.25,
      minWidth: 150,
      field: "activation_expired",
      headerName: "Activated until",
      align: "center",
      headerAlign: "center",
      valueGetter: (text, record, index) => {
        return parseFullDate(record?.activation_expired);
      },
    },
    {
      field: "createdAt",
      minWidth: 150,
      headerName: "Created date",
      align: "center",
      headerAlign: "center",
      valueGetter: (text, record, index) => {
        return parseFullDate(text);
      },
    },

    {
      headerName: "",
      field: "operation",
      minWidth: 50,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        profile && profile.reseller && profile.reseller.update_playlist ? (
          <TableButtons
            buttons={
              !params.row?.payed
                ? [
                    {
                      key: "Playlist",
                      text: translation["Playlist"] || TEXTS["Playlist"],
                      icon: (
                        <div
                          onClick={(e) => {
                            console.log(params);
                          }}
                          className={classes["playlist-icon-container"]}
                        >
                          {ICONS.PLAYLIST}
                        </div>
                      ),
                    },
                    {
                      key: "Activate",
                      text: translation["Activate"] || TEXTS["Activate"],
                      icon: <TagOutlined />,
                    },
                    {
                      key: "Delete",
                      text: params.row?.is_disabled
                        ? translation["Enable"] || TEXTS["Enable"]
                        : translation["Delete"] || TEXTS["Delete"],
                      icon: ICONS.TRASH,
                    },
                  ]
                : [
                    {
                      key: "Playlist",
                      text: translation["Playlist"] || TEXTS["Playlist"],
                      icon: (
                        <div className={classes["playlist-icon-container"]}>
                          {ICONS.PLAYLIST}
                        </div>
                      ),
                    },
                    {
                      key: "Delete",
                      text: params.row?.is_disabled
                        ? translation["Enable"] || TEXTS["Enable"]
                        : translation["Delete"] || TEXTS["Delete"],
                      icon: ICONS.TRASH,
                    },
                  ]
            }
            handleMenuClick={(e) => handleMenuClick(e, params.row)}
          />
        ) : (
          <TableButtons
            buttons={[
              {
                key: "Delete",
                text: params.row?.is_disabled
                  ? translation["Enable"] || TEXTS["Enable"]
                  : translation["Delete"] || TEXTS["Delete"],
                icon: ICONS.TRASH,
              },
            ]}
            handleMenuClick={(e) => handleMenuClick(e, params.row)}
          />
        ),
    },
  ];

  const getDevices = () => {
    setLoading(true);

    const query = {
      limit,
      pagination: 1,
      page: currentPage,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.mac) {
      query.search["mac"] = search.mac[0];
    }

    if (search.comment) {
      query.search["$reseller_activation.comment$"] = search.comment[0];
    }

    query.search = JSON.stringify(query.search);

    const queryDate = getQueryDate(date);

    if (queryDate) query.between = queryDate;

    function callback(data) {
      setLoading(false);
      setTotal(data.count);
      setLimit(data.limit);
      setCurrentPage(data.currentPage);
      setDevices(data.rows);
      setbackupDevicedata(data.rows);
      console.log(data.rows);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ACTIVATED_DEVICE.GET(query, callback, errorCallback);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getDevices();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search, date, limit, currentPage, sort, activationExpired]);

  const handleSearch = (value) => {
    setSearchValue(value);





    
    
    function searchTransactions(transactions, query) {
      const queryLower = String(query).toLowerCase();







      
      let filterarea= transactions.filter(transaction => {

       

       return Object.values(transaction).some(value => 
          {
            
let newvalue=value
            if(String(value).includes('T')){
              

              
              newvalue=parseFullDate(String(value))


            }




            return value !== null && String(newvalue).toLowerCase().includes(queryLower)
          }
      );

   
      });




      return filterarea



  }

  setDevices(searchTransactions(backupDevicedata,value));
  };

  return (
    <Pages title={translation["Devices"] || TEXTS["Devices"]}>
      <Card>
        <div className="datagrid">
          <DataGrid
            autoHeight
            pagination
            rows={devices}
            rowCount={total}
            columns={columns}
            checkboxSelection
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[7, 10, 25, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slots={{ toolbar: ServerSideToolbar }}
            slotProps={{
              baseButton: {
                size: "medium",
                variant: "tonal",
              },
              toolbar: {
                value: searchValue,
                clearSearch: () => handleSearch(""),
                onChange: (event) => handleSearch(event.target.value),
              },
            }}
          />
        </div>
      </Card>

      {/* <Table
        rowKey="id"
        bordered
        onChange={handleTableChange}
        loading={{
          spinning: loading,
          indicator: (
            <Spin
              indicator={
                <img
                  src="./assets/images/logo.svg"
                  style={{
                    width: 100,
                    height: 100,
                    transform: `translateY(-40px)`,
                  }}
                  alt=""
                />
              }
            />
          ),
        }}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        rowClassName={(record, index) => {
          return !record.payed ? classes["expired-row"] : "";
        }}
        columns={columns}
        dataSource={devices}
        size="small"
        scroll={{
          x: "max-content",
        }}
      /> */}

      <ActivateDeviceDrawer
        visible={user.VisibleActivateDeviceDrawer}
        onClose={() => {
          setUser({
            ...user,
            VisibleActivateDeviceDrawer: false,
          });

          setVisibleActivateDeviceDrawer(false);
          if (currentDevice) setCurrentDevice(null);
        }}
        getDevices={getDevices}
        removeSearchSorter={removeSearchSorter}
        currentDevice={currentDevice}
      />

      <AddExistingDeviceDrawer
        visible={user.VisibleAddExistingDeviceDrawer}
        onClose={() => {
          setUser({
            ...user,
            VisibleAddExistingDeviceDrawer: false,
          });
        }}
        getDevices={getDevices}
        removeSearchSorter={removeSearchSorter}
      />

      <DeviceDrawer
        visible={visibleDeviceDrawer}
        onClose={() => setVisibleDeviceDrawer(false)}
        getDevices={getDevices}
      />

      <PlaylistsDrawer
        visible={visiblePlaylistDrawer}
        onClose={() => setVisiblePlaylistDrawer(false)}
        deviceId={deviceId}
      />
    </Pages>
  );
}
