import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Layout } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { actions } from "../../store/index";

import { REQUESTS } from "../../api/requests";

import Profile from "./Profile";
import WalletBlock from "./WalletBlock";
import LanguageBlock from "./LanguageBlock";
import TEXTS from "../../config/texts";
import classes from "../styles/header.module.scss";
import userContext from "../../context/UserContext";

// The Header component is used to display a header to the user. From props we get the collapsed state of the
// sidebar and the function that will be called when the sidebar is collapsed. The Header component also
// contains the Profile, WalletBlock and LanguageBlock components. PaymentSettings is also called here.
// PaymentSettings is called here because it is used in the WalletBlock component.

function Headers({ collapsed, setCollapsed, setOpenMenuDrawer }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [credit, setCredit] = useState("");
  const [darkmode, setdarkmode] = useState(false);

  const dispatch = useDispatch();
  const translation = useSelector((state) => state.globalState.translation);
  const profile = useSelector((state) => state.globalState.profile);

  const { setProfile } = bindActionCreators(actions, dispatch);
  const { setPaymentSettings } = bindActionCreators(actions, dispatch);
  const { user, setUser } = useContext(userContext);
  const getProfile = () => {
    function callback(data) {
      setProfile(data);
      setCredit(data.reseller?.total_activations);
    }

    REQUESTS.PROFILE.GET(callback);
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setPaymentSettings(data);
    });
  };

  useEffect(() => {
    getProfile();
    getPaymentSettings();
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);
  return (
    <Layout.Header className={classes["header"]}>
      {/* {windowWidth > 800 ? (
        <div>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </div>
      ) : (
        <MenuOutlined onClick={() => setOpenMenuDrawer(true)} />
      )} */}

      <div className="header-upper">
        <div>
          <img
            src="/assets/images/logo.svg"
            className={classes["logo"]}
            alt=""
          />

          <div className={classes["header-item-block"]}>
          
<Profile getProfile={getProfile} />
<div className={classes["line-block"]}> </div>
            <LanguageBlock />
            {!darkmode ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                ariaHidden="true"
                role="img"
                fontSize="1.625rem"
                class="iconify iconify--tabler"
                style={{ cursor: "pointer" }}
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                onClick={(e) => {
                  document.querySelector("body").classList.add("darkmode");
                  setdarkmode(true);
                }}
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M12 3h.393a7.5 7.5 0 0 0 7.92 12.446A9 9 0 1 1 12 2.992zm5 1a2 2 0 0 0 2 2a2 2 0 0 0-2 2a2 2 0 0 0-2-2a2 2 0 0 0 2-2m2 7h2m-1-1v2"
                ></path>
              </svg>
            ) : (
              <svg
                onClick={(e) => {
                  document.querySelector("body").classList.remove("darkmode");
                  setdarkmode(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                ariaHidden="true"
                role="img"
                style={{ cursor: "pointer" }}
                fontSize="1.625rem"
                class="iconify iconify--tabler"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                >
                  <circle cx="12" cy="12" r="4"></circle>
                  <path d="M3 12h1m8-9v1m8 8h1m-9 8v1M5.6 5.6l.7.7m12.1-.7l-.7.7m0 11.4l.7.7m-12.1-.7l-.7.7"></path>
                </g>
              </svg>
            )}
            {/* <WalletBlock /> */}

            
            

            <div className={classes["line-block"]}> </div>

            <div className={classes["credit-refresh-btn-block"]}>
              {profile && profile.reseller && (
                <strong>
                  {`${translation["Credit"] || TEXTS["Credit"]}: ${
                    credit || 0
                  }`}
                </strong>
              )}
              <SyncOutlined
                onClick={() => {
                  setCredit("");
                  setTimeout(() => {
                    getProfile();
                  }, 100);
                }}
                className={classes["refresh-btn"]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout.Header>
  );
}

export default Headers;
