import { useSelector } from "react-redux";

import LoginForm from "./components/LoginForm";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { useEffect, useState } from "react";

import classes from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../config";

// We have a login page component that has a login form component and a forgot password form component. We have a state that is active. When the state is
// active, it renders the login form component. When the state is not active, it renders the forgot password form component. We have a onLoginForgotForm
// function that is called when the user clicks on the forgot password link. It changes the state and renders the forgot password form component.

export default function LoginPage() {
  const navigate = useNavigate();

  const appInfo = useSelector((state) => state.globalState.appInfo);

  const [active, setActive] = useState(true);

  const onLoginForgotForm = (event) => {
    event.preventDefault();
    setActive(!active);
  };

  useEffect(() => {
    if (localStorage.getItem(CONSTANTS.JWT)) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div
      className={classes["login-page"]}
      style={{
        backgroundImage: ` url(${appInfo?.reseller_login_background} )`,
      }}
    >
      <div className={classes["login-page-left-side"]}>
        <img src="./assets/images/report.svg" />
      </div>

      <div className={classes["login-form-block"]}>
        <div className={classes["login-form-block-container"]}>
          <div className={classes["login-container-img"]}>
            <img loading={"lazy"} src="./assets/images/logo.svg" alt="img" />
          </div>

          <div className={classes["login-page-form"]}>
            {active ? (
              <LoginForm active={onLoginForgotForm} />
            ) : (
              <ForgotPasswordForm active={onLoginForgotForm} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
