import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import TEXTS from "../config/texts";

import { REQUESTS } from "../api/requests";

import classes from "./style/validateMac.module.scss";
import CustomTextField from "./mui/text-field";

export default function ValidateMacAddress({
  // setMacIsValid,
  value = "",
  setValue,
  setDevice,
}) {
  const translation = useSelector((state) => state.globalState.translation); //  is used to get the translation data from the global state.

  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const validateMacAddress = () => {
    if (value?.length === 0) {
      return;
    }

    function callback(data) {
      if (setDevice) {
        setDevice(data);
      }

      // setMacIsValid(true);
      setMessage({
        text:
          translation["Mac address is valid"] || TEXTS["Mac address is valid"],
        type: true,
      });
    }

    function errorCallback(error) {
      // setMacIsValid(false);
      setMessage({
        text:
          translation["Mac address is not valid"] ||
          TEXTS["Mac address is not valid"],
        type: false,
      });
    }

    REQUESTS.MAC_VALIDATION({ mac: value }, callback, errorCallback);
  };

  const generateMacFormat = (e) => {
    var mac = e.target.value.replace(/[^0-9a-z]/gi, "");
    var result = "";

    for (var i = 0; i < mac.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += mac[i];
    }
    setValue(result.substring(0, 17));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      validateMacAddress();
    }, 1000);

    if (value?.length == 0) {
      setMessage({
        text: "",
        type: false,
      });
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return (
    <div>
      <Form.Item
        rules={[
          {
            required: true,
            message:
              translation["This field is required!"] ||
              TEXTS["This field is required!"],
          },
        ]}
      >
        <div className={classes["input-flex-area"]}>
          <label htmlFor="">
            {translation["Mac address"] || TEXTS["Mac address"]}
          </label>

          <div style={{ width: "100%" }}>
            <CustomTextField
              value={value}
              onChange={generateMacFormat}
              name="mac"
              className={classes["mac-inp"]}
              placeholder={
                translation["Enter device mac address"] ||
                TEXTS["Enter device mac address"]
              }
            />
          </div>

          {/* <input
            value={value}
            onChange={generateMacFormat}
            name="mac"
            className={classes["mac-inp"]}
          /> */}
        </div>
        {message.text != "" && (
          <div
            className={classes[message.text ? "message-open" : "message-close"]}
          >
            <span
              className={classes[message.type ? "success-text" : "error-text"]}
            >
              {message.text}
            </span>
          </div>
        )}
      </Form.Item>
    </div>
  );
}
