import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, Modal } from "antd";
import TEXTS from "../../../config/texts";
import { REQUESTS } from "../../../api/requests";
import Message from "../../../components/message";
import CustomTextField from "../../../components/mui/text-field";

export default function SubresellerDrawer({
  visible,
  onClose,
  current = null,
  getSubresellers,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [inputValue, setInputValue] = useState("");

  const translation = useSelector((state) => state.globalState.translation);
  const inputRef = useRef(null);

  const [form] = Form.useForm();

  function onFinish(values) {
    setLoading(true);

    const body = {
      name: values.name,
      surname: values.surname || "",
      email: values.email,
      phone: inputValue,
      company: values.company || "",
      address: values.address || "",
      country: values.country || "",
    };

    if (values.password.length) {
      body.password = values.password;
    }

    if (current) {
      body["id"] = current.id;

      REQUESTS.SUBRESELLERS.EDIT(
        body,
        (data) => {
          setLoading(false);
          getSubresellers();
          onClose();
        },
        (err) => {
          setLoading(false);
          setMessage({
            text: err,
            type: false,
          });
        }
      );
    } else {
      REQUESTS.SUBRESELLERS.ADD(
        body,
        (data) => {
          setLoading(false);
          getSubresellers();
          onClose();
          setInputValue("");
        },
        (err) => {
          setLoading(false);
          setMessage({
            text: err,
            type: false,
          });
        }
      );
    }
  }

  const onSubmit = () => {
    form.submit();
    setMessage({
      text: "",
      type: true,
    });
  };

  const onValuesChange = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "surname",
          value: current.surname,
        },

        {
          name: "company",
          value: current.company,
        },

        {
          name: "country",
          value: current.country,
        },
        {
          name: "email",
          value: current.email,
        },

        {
          name: "address",
          value: current.address,
        },

        {
          name: "password",
          value: "",
        },
      ]);

      setInputValue(current.phone);
    } else {
      form.resetFields();
      setInputValue("");
    }
  }, [current, visible]);

  useEffect(() => {
    if (!visible) {
      setMessage({
        text: "",
        type: true,
      });
    }
  }, [visible]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "surname",
          value: current.surname,
        },

        {
          name: "company",
          value: current.company,
        },

        {
          name: "country",
          value: current.country,
        },
        {
          name: "email",
          value: current.email,
        },

        {
          name: "address",
          value: current.address,
        },

        {
          name: "password",
          value: "",
        },
      ]);

      setInputValue(current.phone);
    }
  }, [current, visible]);

  return (
    <div>
      <Modal
        width={windowWidth > 600 ? 600 : "75vw"}
        title={
          current
            ? translation["Edit Reseller"] || TEXTS["Edit Reseller"]
            : translation["Add Reseller"] || TEXTS["Add Reseller"]
        }
        centered
        onClose={onClose}
        open={visible}
        onCancel={onClose}
        onOk={onClose}
        footer={[]}
      >
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <div className="subreseller-drawer-content">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                },
              ]}
            >
              <div className="input-wrapper">
                <label htmlFor="#">
                  {translation["Name"] || TEXTS["Name"]}
                </label>

                <CustomTextField
                  value={current?.name}
                  placeholder={translation["Name"] || TEXTS["Name"]}
                />

                {/* <Input /> */}
              </div>
            </Form.Item>

            {/* <Form.Item
              name="surname"
              label={translation["Surname"] || TEXTS["Surname"]}
            >
              <Input />
            </Form.Item> */}
            {/* <div className="phone-inp-container">
              <label className="phone-label">
                {translation["Phone"] || TEXTS["Phone"]}
              </label>
              <input
                type="text"
                value={inputValue}
                ref={inputRef}
                name="phone"
                onChange={(e) => {
                  const validInput = e.target.value.replace(/[^+0-9]/g, "");
                  if (validInput.includes("+")) {
                    setInputValue(validInput);
                  } else {
                    setInputValue(`+${validInput}`);
                  }
                }}
                onKeyDown={(e) => {
                  const cursorPosition = inputRef.current.selectionStart;
                  if (cursorPosition == 0) {
                    inputRef.current.selectionStart = e.target.value.length;
                  }
                }}
                className="phone-input"
              />
            </div> */}

            <Form.Item
              name="email"
              // label={translation["Email"] || TEXTS["Email"]}
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                },
              ]}
            >
              <div className="input-wrapper">
                <label htmlFor="#">
                  {translation["Email"] || TEXTS["Email"]}
                </label>
                <CustomTextField
                  value={current?.email}
                  placeholder={translation["Email"] || TEXTS["Email"]}
                />

                {/* <Input /> */}
              </div>
            </Form.Item>
            {/* <Form.Item
              name="company"
              label={translation["Company"] || TEXTS["Company"]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address"
              label={translation["Address"] || TEXTS["Address"]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label={translation["Country"] || TEXTS["Country"]}
            >
              <Select showSearch={true}>
                {country.map((item) => {
                  return (
                    <Select.Option key={item.code} value={`${item.name}`}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item> */}
            <Form.Item
              name="password"
              rules={[
                {
                  required: !current,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                },
              ]}
            >
              <div className="input-wrapper">
                <label htmlFor="#">
                  {translation["Password"] || TEXTS["Password"]}
                </label>
                <CustomTextField
                  value={form.getFieldValue("password")}
                  placeholder={translation["Password"] || TEXTS["Password"]}
                />
                {/* <Input.Password /> */}
              </div>
            </Form.Item>
          </div>{" "}
          <Message message={message} />
          <Form.Item style={{ marginBottom: 0 }}>
            <div className="subreseller-drawer-content_save-btn-container">
              <Button
                type="primary"
                onClick={onSubmit}
                loading={loading}
                style={{ background: "#756AF0" }}
                className="subreseller-drawer-content_save-btn-container_btn"
              >
                {translation["Save"] || TEXTS["Save"]}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
