// ** MUI Import
import MenuItem from "@mui/material/MenuItem";

// ** Custom Component Import

import CustomTextFieldLanguage from "../language-text-field";

const SelectCustomLanguage = ({ children, placeholder }) => {
  return (
    <div>
      <CustomTextFieldLanguage select>{children}</CustomTextFieldLanguage>
    </div>
  );
};

export default SelectCustomLanguage;
