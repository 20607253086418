import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Spin, Table } from "antd";

import { REQUESTS } from "../../api/requests";

import ICONS from "../../config/icons";
import TEXTS from "../../config/texts";
import { convertToISO8601, parseFullDate } from "../../config/formats";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
} from "../../config/config";

import { DataGrid } from "@mui/x-data-grid";

import MyIcon from "../../components/IconsAntSvg";
import Pages from "../../components/Pages";
import Tags from "../../components/Tags";
import BuyMoreActivationsDrawer from "../../layout/components/BuyMoreActivationsDrawer";
import userContext from "../../context/UserContext";
import Card from "../dashboard/components/Card";
import ServerSideToolbar from "../../components/ServerSideToolbar";

/**
 * Activations history page. We have a table that shows the activations history. We can search, sort, and filter the table.
 * From here we can buy more activations. BuyMoreActivations drawer is rendered when we click on the buy more activations
 * button.
 * @returns
 */

export default function ActivationsHistory() {
  // appInfo for the app title and favicon. translation for the texts. navigate for the navigation. searchParams for
  // the search params.
  const { user, setUser } = useContext(userContext);
  const appInfo = useSelector((state) => state.globalState.appInfo);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });
  const [searchValue, setSearchValue] = useState("");

  // translation for the texts.
  const translation = useSelector((state) => state.globalState.translation);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // visibleBuyMoreActivationsDrawer for the buy more activations drawer. Drawers are rendered when we click on the
  // buy more activations button.
  const [visibleBuyMoreActivationsDrawer, setVisibleBuyMoreActivationsDrawer] =
    useState(false);

  // activationsHistory for the activations history. total for the total count of the activations history.
  const [activationsHistory, setActivationsHistory] = useState([]);
  const [backupactivationdata, setbackupactivationdata] = useState([]);

  // total for the total count of the activations history.
  const [total, setTotal] = useState(0);

  // limit for the limit of the activations history.
  const [limit, setLimit] = useState(searchParams.get("limit") || 10);

  //laoading for showing the loading icon in the table.
  const [loading, setLoading] = useState(false);

  // currentPage for the current page of the activations history. It is used for the pagination.
  const [currentPage, setCurrentPage] = useState(1);

  // sort for the sort of the activations history. It is used for the sorting. It is an array. The first element is the key and the
  // second element is the order.
  const [sort, setSort] = useState(["id", "DESC"]);

  // date for the date of the activations history. It is used for the filtering.
  const [date, setDate] = useState("");

  // search for the search of the activations history. It is used for the searching. It is an object. The keys are the columns and the
  // values are the search values.
  const [search, setSearch] = useState(searchParams.get("search") || {});

  const searchInput = useRef(null);

  const paymentSettings = useSelector(
    (state) => state.globalState.pymentSettings
  );

  // const columns = [
  //   {
  //     title: "#",
  //     width: 60,
  //     dataIndex: `id`,
  //     align: "center",
  //     key: "index",
  //     render: (text, record, index) => {
  //       return limit * (currentPage - 1) + index + 1;
  //     },
  //   },
  //   {
  //     title: `${translation["Method"] || TEXTS["Method"]}`,
  //     dataIndex: "method",
  //     align: "center",
  //     key: "method",
  //     ...getColumnSearchProps(searchInput),
  //   },
  //   {
  //     title: `${translation["Transaction id"] || TEXTS["Transaction id"]}`,
  //     dataIndex: "transaction_id",
  //     align: "center",
  //     key: "transaction_id",
  //     render: (record, text, index) => {
  //       return record || <Tags />;
  //     },
  //     ...getColumnSearchProps(searchInput),
  //   },
  //   {
  //     title: `${translation["Count"] || TEXTS["Count"]}`,
  //     dataIndex: "count",
  //     align: "center",
  //     key: "count",
  //     sorter: true,
  //   },
  //   {
  //     title: `${translation["Status"] || TEXTS["Status"]}`,
  //     dataIndex: "status",
  //     align: "center",
  //     key: "status",
  //     sorter: true,
  //     render: (record, text, index) => {
  //       if (record) {
  //         return <MyIcon children={ICONS.CHECK} />;
  //       } else {
  //         return <MyIcon children={ICONS.TIMES} />;
  //       }
  //     },
  //   },
  //   {
  //     title: `${translation["Price"] || TEXTS["Price"]}`,
  //     dataIndex: `price`,
  //     align: "center",
  //     key: "price",
  //     render: (record, text, index) => {
  //       return `${record} ${paymentSettings?.currency}`;
  //     },
  //     sorter: true,
  //   },
  //   {
  //     title: `${translation["Date"] || TEXTS["Date"]}`,
  //     dataIndex: "createdAt",
  //     align: "center",
  //     key: "createdAt",
  //     sorter: true,
  //     render: (record, text, index) => {
  //       return parseFullDate(record);
  //     },
  //     ...getColumnDateProps(setDate),
  //   },
  // ];

  const columns = [
    // {
    //   flex: 0.25,
    //   field: "id",
    //   headerName: "Id",
    // },
    {
      flex: 0.25,
      minWidth: 180,
      field: "method",
      headerName: "Method",
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 0.25,
      minWidth: 180,
      field: "transaction_id",
      headerName: "Transaction id",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.transaction_id || <Tags />;
      },
    },

    {
      flex: 0.25,
      minWidth: 100,
      field: "count",
      align: "center",
      headerName: "Count",
      headerAlign: "center",
      valueGetter: (text, record, index) => {
        return record.count;
      },
    },

    {
      flex: 0.25,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.status) {
          return <MyIcon children={ICONS.CHECK} />;
        } else {
          return <MyIcon children={ICONS.TIMES} />;
        }
      },
    },

    {
      flex: 0.25,
      minWidth: 80,
      field: "price",
      headerName: "Price",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return `${params.row.price}`;
      },
    },

    {
      field: "createdAt",
      minWidth: 150,
      headerName: "Created date",
      align: "center",
      headerAlign: "center",
      valueGetter: (text, record, index) => {
        return parseFullDate(text);
      },
    },
  ];

  // handleTableChange is used for the table change. It is used for the pagination, sorting, and filtering.
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  // getActivationsHistory is used for getting the activations history.
  const getActivationsHistory = () => {
    setLoading(true);

    // query is the query for the activations history. It is used for the pagination, sorting, searching, and filtering.
    const query = {
      limit,
      search: {},
      pagination: 1,
      page: currentPage,
      sort: JSON.stringify(sort),
    };

    if (search.transaction_id) {
      query.search["transaction_id"] = search.transaction_id[0];
    }
    if (search.method) {
      query.search["method"] = search.method[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    const queryDate = getQueryDate(date);

    if (queryDate) query.between = queryDate;

    function callback(data) {
      setLoading(false);
      setTotal(data.count);
      setLimit(data.limit);
      setCurrentPage(data.currentPage);
      setActivationsHistory(data.rows);
      setbackupactivationdata(data.rows);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    navigate({
      search: `${createSearchParams(query)}`,
    });

    REQUESTS.ACTIVATIONS_HISTORY.GET(query, callback, errorCallback);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getActivationsHistory();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [sort, date, limit, currentPage, search]);

  const handleSearch = (value) => {
    setSearchValue(value);

    

    function searchTransactions(transactions, query) {
      const queryLower = String(query).toLowerCase();







      
      let filterarea= transactions.filter(transaction => {

       

       return Object.values(transaction).some(value => 
          {
            
let newvalue=value
            if(String(value).includes('T')){
              

              
              newvalue=parseFullDate(String(value))


            }




            return value !== null && String(newvalue).toLowerCase().includes(queryLower)
          }
      );

   
      });




      return filterarea



  }
  setActivationsHistory(searchTransactions(backupactivationdata,value))
  
    
  };

  return (
    <Pages
      title={translation["Credits history"] || TEXTS["Credits history"]}
      onClick={() => setVisibleBuyMoreActivationsDrawer(true)}
    >
      <Card>
        <div className="datagrid">
          <DataGrid
            autoHeight
            pagination
            rows={activationsHistory}
            rowCount={total}
            columns={columns}
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[7, 10, 25, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slots={{ toolbar: ServerSideToolbar }}
            slotProps={{
              baseButton: {
                size: "medium",
                variant: "tonal",
              },
              toolbar: {
                value: searchValue,
                clearSearch: () => handleSearch(""),
                onChange: (event) => handleSearch(event.target.value),
              },
            }}
          />
        </div>
      </Card>
      {/* <Table
        rowKey="id"
        bordered
        loading={{
          spinning: loading,
          indicator: (
            <Spin
              indicator={
                <img
                  src="./assets/images/logo.svg"
                  style={{
                    width: 100,
                    height: 100,
                    transform: `translateY(-40px)`,
                  }}
                  alt=""
                />
              }
            />
          ),
        }}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        columns={columns}
        dataSource={activationsHistory}
        scroll={{
          x: "max-content",
        }}
        size="small"
      /> */}

      <BuyMoreActivationsDrawer
        getActivationsHistory={getActivationsHistory}
        visible={user.visibleBuyMoreActivationsDrawer}
        onClose={() => {
          setUser({
            ...user,
            visibleBuyMoreActivationsDrawer: false,
          });
        }}
      />
    </Pages>
  );
}
