import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin, Modal } from "antd";

import { actions } from "../../store/index";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import CONSTANTS from "../../config/index";

import { REQUESTS } from "../../api/requests";

import TEXTS from "../../config/texts";

import PaypalButton from "../../components/PaypalButton";

import classes from "../styles/buyMoreActivationsDrawer.module.scss";
import StripeButton from "../../components/StripeButton";

import visaImg from "../../images/visa.png";
import axios from "axios";

// mui imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SelectCustom from "../../components/mui/select";
import { MenuItem } from "@mui/material";

// BuyMoreActivationsDrawer Component is used to show drawer when user wants to buy more activations.
// It gets activated when user clicks on Buy More Activations button in the header, and when user clicks on Buy More Activations button in the
// Activations page's buy more activations button.

const discountStyle = {
  fontSize: "0.8rem",
  color: "#00660a",
  fontWeight: 600,
};

const BuyMoreActivationsDrawer = ({
  visible,
  onClose,
  getActivationsHistory,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();

  // translation is used to get translation from the global state. It is used to show translation in the component.
  // profile is used to get profile from the global state. It is used to get user's current activations count.
  const { translation, profile } = useSelector((state) => state.globalState);

  // loading is used to show loading icon when user is buying more activations.
  const [loading, setLoading] = useState(false);

  // privacyPolicy is used to check if user has accepted privacy policy.
  const [privacyPolicy, setPrivacyPolicy] = useState(true);

  // selectedPackage is used to get selected package from the select component. It is used to get selected package id.
  // It is used to get selected package id.
  const [selectedPackage, setSelectedPackage] = useState("");

  // paymentSettings is used to get payment settings from the server. It is used to get payment settings for the paypal and stripe buttons.
  // here we get paypal client id, currency and paypal enabled status.
  // const [paymentSettings, setPaymentSettings] = useState(null);

  //activationPackages is a list of packages. It is used for show options in the select component.
  const [activationPackages, setActivationPackages] = useState([]);

  const { setPaymentSettings } = bindActionCreators(actions, dispatch);
  const paymentSettings = useSelector(
    (state) => state.globalState.pymentSettings
  );

  // getActivationsPackages is used to get activation packages from the server. It is used to get packages for the select component.
  const getActivationsPackages = () => {
    function callback(data) {
      if (data.rows) {
        const list = data.rows.map((item) => ({
          ...item,
          label: `${item.name} ${item.price} ${paymentSettings.currency} (${
            item.count
          } ${translation["Credits"] || TEXTS["Credits"]})`,
          value: item.id,
        }));

        setActivationPackages(list);
      }
    }

    function errorCallback() {}

    REQUESTS.ACTIVATION_PACKAGES(callback, errorCallback);
  };

  // getPaymentSettings is used to get payment settings from the server. It is used to get payment settings for the paypal button.
  // here we get paypal client id, currency and paypal enabled status.
  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setPaymentSettings(data);
    });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  // renderBuyButton is used to render buy button. It is used to show buy button when user has selected a package and accepted privacy policy.
  const renderPaypalButton = () => {
    if (
      privacyPolicy &&
      paymentSettings &&
      paymentSettings.paypal_client_id &&
      paymentSettings.paypal_enabled &&
      paymentSettings.currency &&
      selectedPackage &&
      !loading
    ) {
      return (
        <PaypalButton
          paymentSettings={paymentSettings}
          activationPackages={activationPackages}
          loading={() => setLoading(false)}
          resellerId={profile?.reseller?.id}
          selectedPackage={selectedPackage}
          onClose={onClose}
        />
      );
    }
  };

  //renderBuyButton is used to render buy button. It is used to show buy button when user has selected a package and accepted privacy policy.
  const renderStripeButton = () => {
    if (
      privacyPolicy &&
      paymentSettings &&
      paymentSettings.stripe_enabled &&
      selectedPackage
    ) {
      return (
        <StripeButton
          selectedPackage={selectedPackage}
          resellerId={profile?.reseller?.id}
        />
      );
    }
  };

  const payWithIneco = () => {
    let store_key = paymentSettings?.cmi_client_id;
    let gateway_url = paymentSettings.geteway_url;
    let package_id = selectedPackage?.id;
    let client_id = profile?.reseller?.id;
    let reseller_email = profile?.reseller?.email;
    let f_name = profile?.reseller?.name;
    let s_name = profile?.reseller?.surname;

    if (!store_key || !package_id || !client_id) {
      console.error(`Missing ${store_key} or ${package_id} or ${client_id}`);
      return;
    }

    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store_key}`,
      },
      body: JSON.stringify({
        amount: selectedPackage.price * 100,
        currency: "USD",
        email: reseller_email,
        first_name: `${client_id} ${f_name}`,
        last_name: s_name,
        metadata: {
          package_id: selectedPackage.id,
          client_id: client_id,
          email2: reseller_email,
          appnames: "smarters",
        },
      }),
    };

    fetch(gateway_url, config)
      .then((res) => res.json())
      .then((response) => {
        window.location.href = response.data.authorization_url;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const pay = () => {
    if (profile?.reseller?.id && selectedPackage) {
      let host = `${CONSTANTS["API_HOST"]}payment/dlocal?type=reseller_bay_activation&activation_package_id=${selectedPackage.id}&reseller_id=${profile.reseller.id}`;
      window.open(host, "_blank");
      console.log(host);
    }
  };

  useEffect(() => {
    if (!visible) {
      setLoading(false);
      setPrivacyPolicy(true);
      setSelectedPackage("");
    } else {
      getPaymentSettings();
    }
  }, [visible]);

  useEffect(() => {
    getActivationsPackages();
  }, [paymentSettings]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);
  return (
    <Modal
      width={windowWidth > 600 ? 600 : "75vw"}
      className={classes["buy-more-drawer"]}
      title={translation["Buy Credits"] || TEXTS["Buy Credits"]}
      centered
      onClose={onClose}
      onOk={onClose}
      onCancel={onClose}
      open={visible}
      footer={[]}
    >
      <div className={classes["buy-more-form"]}>
        <div className={classes["buy-more-form-input-wrapper"]}>
          <label>{translation["Package"] || TEXTS["Package"]}</label>

          <div style={{ width: "100%", marginTop: 10 }}>
            <SelectCustom
              placeholder={
                translation["Select package"] || TEXTS["Select package"]
              }
            >
              {activationPackages &&
                activationPackages.map((item) => {
                  const { id } = item;
                  return (
                    <MenuItem
                      key={id}
                      value={id}
                      onClick={() => {
                        setSelectedPackage(item);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
            </SelectCustom>
          </div>

          {/* <Select
            placeholder={
              translation["Select package"] || TEXTS["Select package"]
            }
            className={classes["select"]}
            style={{ width: "100%", marginTop: 10 }}
            value={selectedPackage?.value}
            onChange={(value, item) => {
              setSelectedPackage(item);
            }}
            options={activationPackages}
          /> */}
        </div>

        <div className={classes["checkbox-block"]}>
          {/* <Checkbox /> */}

          <FormControl>
            <RadioGroup>
              <FormControlLabel
                onChange={(e) => setPrivacyPolicy(e.target.checked)}
                checked={privacyPolicy}
                value="privacypolicy"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>

          <a
            href={CONSTANTS.REACT_APP_WEB_DOMAIN + "/privacy-policy"}
            target={"_blank"}
          >
            {translation["Privacy policy"] || TEXTS["Privacy policy"]}
          </a>
        </div>

        {loading ? (
          <div className={classes["paypal-loading"]}>
            <Spin indicator={antIcon} />
          </div>
        ) : null}

        {paymentSettings && privacyPolicy && selectedPackage && (
          <div>
            {paymentSettings.cmi_enabled && (
              <div className={classes["ineco-button"]} onClick={payWithIneco}>
                <img src={visaImg} alt="visa" />
                <div className={classes["ineco-button__text"]}>
                  <b>Pay with Credit Card ${selectedPackage?.price}</b>
                </div>
              </div>
            )}

            {paymentSettings.dlocal_enabled && (
              <div onClick={pay} className={classes["pixi-payment-button"]}>
                Pixi pay
              </div>
            )}

            {renderPaypalButton()}

            {paymentSettings.paypal_enabled &&
              paymentSettings.stripe_enabled && (
                <div className="separator">
                  {" "}
                  {translation["Or"] || TEXTS["Or"]}
                </div>
              )}
            {renderStripeButton()}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BuyMoreActivationsDrawer;
