import React, { useEffect, useState } from "react";
import Message from "../../components/message";
import { useSelector } from "react-redux";
import { REQUESTS } from "../../api/requests";
import TEXTS from "../../config/texts";

import classes from "../styles/resetEmailDrawer.module.scss";

import { Button, Drawer, Form, Input, Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import TypeCodeDrawer from "./TypeCodeDrawer";
import CustomTextField from "../../components/mui/text-field";

const ResetEmailDrawer = ({ visible, onClose, getProfile }) => {
  const translation = useSelector((state) => state.globalState.translation);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [loading, setLoading] = useState(false);
  const [visibleTypeCodeDrawer, setVisibleTypeCodeDrawer] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });
  //test
  const [okMessage, setOkMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      email: values.email,
    };

    function callback(data) {
      setLoading(false);
      setOkMessage({
        text: data,
        type: true,
      });
      setVisibleTypeCodeDrawer(true);
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.RESET_EMAIL(body, callback, errorCallback);
  };

  const onValuesChange = () => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const closeResetEmailTypeCodeDrawer = () => {
    onClose();
    setVisibleTypeCodeDrawer(false);
  };

  useEffect(() => {
    if (!visible) {
      setMessage({
        text: "",
        type: true,
      });
    } else {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <>
      <Modal
        width={windowWidth > 600 ? 600 : "75vw"}
        title={translation["Reset email"] || TEXTS["Reset email"]}
        placement="right"
        onClose={onClose}
        onCancel={onClose}
        open={visible}
        footer={[]}
      >
        <Form
          form={form}
          name="profile"
          layout={"vertical"}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <div className={classes["reset-block"]}>
            <div className={classes["form-input-block"]}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message:
                      translation["The input is not valid Email!"] ||
                      TEXTS["The input is not valid Email!"],
                  },
                  {
                    required: true,
                    message:
                      translation["This field is required!"] ||
                      TEXTS["This field is required!"],
                  },
                ]}
              >
                <div className={classes["input-flex-area"]}>
                  <label htmlFor="#">
                    {translation["Email"] || TEXTS["Email"]}
                  </label>

                  <div style={{ width: "100%" }}>
                    <CustomTextField
                      onKeyDown={onInputEnter}
                      placeholder={
                        translation["Enter your address"] ||
                        TEXTS["Enter your address"]
                      }
                    />
                  </div>
                </div>

                {/* <Input onKeyDown={onInputEnter} /> */}
              </Form.Item>
            </div>

            <Message message={message} />

            <Form.Item className={classes["reset-button"]}>
              <Button
                loading={loading}
                type="primary"
                size={"large"}
                icon={<CheckOutlined />}
                onClick={onSubmit}
              >
                {translation["Save"] || TEXTS["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <TypeCodeDrawer
        getProfile={getProfile}
        email={form.getFieldValue("email")}
        visible={visibleTypeCodeDrawer}
        onClose={() => setVisibleTypeCodeDrawer(false)}
        closeResetEmailTypeCodeDrawer={closeResetEmailTypeCodeDrawer}
        okMessage={okMessage}
        setOkMessage={() => setOkMessage({ text: "", type: true })}
      />
    </>
  );
};

export default ResetEmailDrawer;
