function parseFullDate(date) {
    
    const dateObj = new Date(date);

    let year = dateObj.getFullYear();
    let day = dateObj.getDate();
    let month = dateObj.getMonth()+1;
    let minutes = dateObj.getMinutes();
    let hours = dateObj.getHours();

    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}


function convertToISO8601(dateString) {
    
    // Parse the input date string

    console.log(dateString)
    let [datePart, timePart] = dateString.split(' ');
    console.log(timePart)
    let [day, month, year] = datePart.split('/').map(Number);
    let [hours, minutes] = timePart.split(':').map(Number);

    // Create a Date object with the parsed values (using UTC)
    let date = new Date(Date.UTC(year, month - 1, day, hours, minutes, 15, 0));

    // Convert to ISO 8601 format
    return date.toISOString();
}


export { parseFullDate ,convertToISO8601};
