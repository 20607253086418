import classes from "./style/pages.module.scss";
import { Button } from "antd";

// The Pages component is used to display a page to the user.
// From props we get the title of the page, the content of the page and the actions that can be performed on the page.
// The actions can be either a string or an object.

const Pages = ({ children, title, onClick, actions }) => {
  return (
    <div className={classes["blur"]}>
      <div className={classes["header"]}>
        <div className={classes["title"]}>
          <span>{title}</span>
        </div>

        <div className={classes["buttons"]}>
          {typeof actions === "string" && (
            <Button type="primary" size={"large"} onClick={onClick}>
              {actions}
            </Button>
          )}

          {typeof actions === "object" && actions}
        </div>
      </div>

      {children}
    </div>
  );
};

export default Pages;
